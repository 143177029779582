import request from "@/Rest";
import { AUTH_URLS } from "@/Rest/api/urls";

export async function verifyEmail(uidb64, token) {
  const payload = {
    uidb64,
    token,
  };

  try {
    const { data } = await request.POST(AUTH_URLS.verifyEmail, payload);

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}
