<template>
  <div>
    <p>We are confirming your account...</p>
  </div>
</template>

<script>
import { verifyEmail } from "@/services/auth/email.js";
import { useToast } from "vue-toastification";

export default {
  data() {
    return {
      email: null,
    };
  },

  mounted() {
    const uidb64 = this.$route.params.uid;
    const token = this.$route.params.token;

    verifyEmail(uidb64, token).then((response) => {
      if (response) {
        this.toast.info("Your email has been verified!", { timeout: false });
        this.$router.push({
          name: "Login",
        });
        return;
      }
      return this.toast.error("An error has occurred, please try again", {
        timeout: false,
      });
    });
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
};
</script>
